import { capitalize, encodeUri } from './index';

const showDistance = (
  distanceFilterEnabled: boolean,
  autoexpanded: boolean | undefined,
  distanceValue: string | number = 'auto'
): boolean => distanceFilterEnabled && !autoexpanded && distanceValue !== 'auto';

const calculateDistanceUrlParam = (distanceFilterEnabled: boolean, distance?: Readonly<Distance>): string => {
  if (distanceFilterEnabled) {
    if (distance) {
      const { value = 'auto', autoexpanded } = distance;

      return autoexpanded ? `r=auto&ae=${value}` : `r=${value}`;
    }

    return 'r=auto';
  }

  return `r=`;
};
const calculateShowModeUrlParam = (showMode?: string): string => (showMode ? `shm=${showMode}` : '');

const calculateSortByModeUrlParam = (sortBy?: string): string => (sortBy ? `sortby=${sortBy}` : '');

const calculateJobTypeUrlParam = (jobtype?: string): string => (jobtype ? `job_type=${jobtype}` : '');

const calculateJobLocationUrlParam = (joblocation?: string): string => (joblocation ? `job_location_type=${joblocation}` : '');

const calculateContractTypeUrlParam = (contracttype?: string): string => (contracttype ? `contract_type=${contracttype}` : '');


const calculateWhatUrlParam = (what: string): string => what
  ? `w=${encodeUri(what).toLocaleLowerCase()}`
  : '';

const calculateWhereUrlParam = (where: string, distance: string): string => where
  ? `l=${encodeUri(where).toLocaleLowerCase()}&${distance}`
  : '';

export const searchFormatter = (search: Readonly<SearchFormatter>, isBold?: boolean): string => {
  const {
    what,
    where,
    rewrittenWhere,
    distance: { autoexpanded, value: distanceValue, text: distanceText } = {},
  } = search;

  const whereValue = rewrittenWhere || where;
  const { feature: { distanceFilterEnabled = false } = {} } = window.jrConfigGlobal;
  const distanceLabel = showDistance(distanceFilterEnabled, autoexpanded, distanceValue)
    ? `(${distanceText})`
    : '';

  const searchLabel =
    what && whereValue
      ? `${capitalize(what)} / ${capitalize(whereValue)} ${distanceLabel}`
      : `${capitalize(what || whereValue)} ${distanceLabel}`;

  return isBold
    ? `<strong class="search-formatted">${searchLabel.trim()}</strong>`
    : searchLabel.trim();
};

export const searchUrlFormatter = (search: Readonly<SearchFormatter>, jobtype = '', joblocation = '', contracttype = '', sortBy: string = window.jrConfigGlobal.search.sortBy): string => {
  const { what = '', where, rewrittenWhere, distance } = search;
  const whereValue = rewrittenWhere || where;
  const {
    feature: { distanceFilterEnabled = false } = {},
    filters: { showMode } = {},
  } = window.jrConfigGlobal;
  const distanceUrlParam = calculateDistanceUrlParam(distanceFilterEnabled, distance);
  const showModeUrlParam = calculateShowModeUrlParam(showMode);
  const sortByModeUrlParam = calculateSortByModeUrlParam(sortBy);
  const jobTypeUrlParam = calculateJobTypeUrlParam(jobtype);
  const jobLocationUrlParam = calculateJobLocationUrlParam(joblocation);
  const contractTypeUrlParam = calculateContractTypeUrlParam(contracttype);
  const whatUrlParam = calculateWhatUrlParam(what);
  const whereUrlParam = calculateWhereUrlParam(whereValue, distanceUrlParam);
  const urlParams = [whatUrlParam, whereUrlParam, jobTypeUrlParam, jobLocationUrlParam, contractTypeUrlParam, sortByModeUrlParam, showModeUrlParam]

  return `/${urlParams.length > 0 ? `?${urlParams.filter(Boolean).join('&')}` : ''}`;
};

export const radiusFormatter = (
  radius: string,
  jrConfigGlobal: Readonly<JrConfigGlobal> = window.jrConfigGlobal): string =>
  !radius ? 'auto' : `${radius} ${jrConfigGlobal.search.radius.unit}`;
