import { JtagService } from '../../../ts/service/jtag-service.ts';
import { searchUrlFormatter } from '../../../ts/utils/formatters.ts';
import NavigationService from '../../../ts/service/navigation-service.ts';

angular
  .module('jrCommonController', ['jrConfig'])
  .value('searchUrlFormatter', searchUrlFormatter)
  .value('NavigationService', NavigationService)
  .controller('MaskController', MaskController)
  .controller('SearchFormCtrl', SearchFormCtrl)
  .controller('MySearchesAsideCtrl', MySearchesAsideCtrl);

MaskController.$inject = ['$scope', '$rootScope', '$window', 'isMobile', 'MyProfileConfig'];
SearchFormCtrl.$inject = [
  'SearchFormService',
  'NavigationService',
  '$scope',
  'SearchConfig',
  'Messages',
];

MySearchesAsideCtrl.$inject = ['MySearchesService', '$scope', 'searchUrlFormatter'];

function MaskController($scope, $rootScope, $window, isMobile, MyProfileConfig) {
  $rootScope.menuOpened = false;
  $rootScope.overflow = false;
  $rootScope.mask = false;
  $rootScope.jobseekerFirstname = MyProfileConfig.firstname || '';
  $rootScope.jobseekerLastname = MyProfileConfig.lastname || '';

  // set main container min-height based on the left sidebar height
  const closeSidebarHeight = parseInt(
    angular.element(document.querySelector('.left-column .close-sidebar')).css('height'),
    10
  );
  const searchFiltersHeight = parseInt(
    angular.element(document.querySelector('.left-column .search-filters')).css('height'),
    10
  );
  const mainContainerHeight =
    closeSidebarHeight + searchFiltersHeight > $window.innerHeight
      ? closeSidebarHeight + searchFiltersHeight
      : $window.innerHeight;

  angular
    .element(document.querySelector('.main-container-row'))
    .css('min-height', mainContainerHeight);

  function clearOffsetMenus() {
    const mobileMenu = document.getElementsByClassName('mobile-menu')[0];
    const leftSidebar = document.getElementsByClassName('left-sidebar')[0];
    if (mobileMenu) {
      mobileMenu.style.top = '';
    }
    if (leftSidebar) {
      leftSidebar.style.top = '';
    }
  }

  $scope.toggleMobileMenus = function () {
    $rootScope.menuOpened = !$rootScope.menuOpened;
    const mobileMenuOpened = !!$rootScope.menuOpened;

    $rootScope.overflow = mobileMenuOpened;
    $rootScope.mask = mobileMenuOpened;
  };

  $scope.hideMask = function () {
    if ($rootScope.menuOpened === true) {
      $rootScope.menuOpened = !$rootScope.menuOpened;
    }

    if ($rootScope.overflow === true) {
      $rootScope.overflow = !$rootScope.overflow;
    }

    $rootScope.mask = !$rootScope.mask;

    if (isMobile) {
      clearOffsetMenus();
    }

    const opened = document.getElementsByClassName('opened');
    for (let i = 0; i < opened.length; i += 1) {
      angular.element(opened[0]).removeClass('opened');
    }
  };

  $scope.openSearchForm = function () {
    $scope.$broadcast('EnableSearchForm');
  };

  angular.element($window).bind('orientationchange', (e) => {
    if (screen.width >= 768) {
      $rootScope.menuOpened = false;
      $rootScope.overflow = false;
      $rootScope.mask = false;
      $scope.$digest();
    }
  });
}

function SearchFormCtrl(SearchFormService, NavigationService, $scope, SearchConfig, Messages) {
  $scope.mobileSearchFormEnabled = false;
  $scope.isEmptyWhere = !SearchFormService.search.where;
  $scope.isEmptyWhat = !SearchFormService.search.what;
  $scope.isEmptySearch = SearchFormService.search.isEmptySearch;
  $scope.initialWhat = '';
  $scope.initialWhere = '';
  $scope.whereClearButtonEnabled = !!SearchConfig.whereByIp;
  $scope.invalidSearchForm = false;
  $scope.distance = {
    value: SearchFormService.search.distance.value,
    autoexpanded: SearchFormService.search.distance.autoexpanded,
    label:
      SearchFormService.search.distance.value === 'auto'
        ? Messages.DistanceFiltersAutoTitle
        : `${Messages.SortFilterTitle}\xa0${SearchFormService.search.distance.text}`,
    enabled: !SearchConfig.geoCoordinatesNotPresent || !SearchFormService.search.where,
  };

  const smartphoneBanner = document.getElementsByClassName('smartphone-banner')[0];

  $scope.$on('EnableSearchForm', (event) => {
    $scope.enableSearchForm();
  });

  $scope.enableSearchForm = function () {
    $scope.mobileSearchFormEnabled = true;
    document.body.style.overflow = 'hidden';
    if (smartphoneBanner) {
      smartphoneBanner.style.display = 'none';
    }
  };

  const filterElement = document.querySelector('.js-filter-icon');

  if (filterElement) {
    JtagService.trackCustomEventImpression('icon_filter_search');
  }

  $scope.trackIconFilter = () => {
    $scope.advancedSearchVisibled = true;

    JtagService.trackCustomEventClick('icon_filter_search');
  };

  $scope.disableSearchForm = function () {
    $scope.advancedSearchVisibled = false;
    $scope.mobileSearchFormEnabled = false;
    document.body.style.overflow = '';
    document.getElementsByClassName('mobile-search-box')[0].scrollTop = 0;
    if (!$scope.search.what) {
      $scope.search.what = $scope.initialWhat;
    }
    if (!$scope.search.where) {
      $scope.search.where = $scope.initialWhere;
    }

    if (smartphoneBanner) {
      smartphoneBanner.style.display = 'block';
    }
  };

  $scope.$watch('search.country.value', (newValue, oldValue) => {
    // Redirect to different country
    if (newValue !== undefined && oldValue !== undefined) {
      NavigationService.toCountry(newValue);
    }
  });

  $scope.$watchCollection('search', (newValue) => {
    if (newValue !== undefined) {
      SearchFormService.search = angular.extend(SearchFormService.search, newValue);
    } else {
      $scope.search = angular.copy(SearchFormService.search);
    }
  });

  $scope.$watch('search.what', (newValue, oldValue) => {
    $scope.isEmptyWhat = !newValue;
    $scope.initialWhat = oldValue;
  });

  $scope.$watch('search.where', (newValue, oldValue) => {
    $scope.isEmptyWhere = !newValue;
    $scope.initialWhere = oldValue || '';
    $scope.distance.value = !newValue ? 'auto' : $scope.distance.value;
    $scope.distance.enabled = !$scope.isEmptyWhere;
    if ($scope.isEmptyWhere) {
      $scope.distance.label = Messages.DistanceFiltersAutoTitle;
    }
  });

  $scope.clearInput = function (inputType) {
    if (inputType === 'what') {
      $scope.isEmptyWhat = true;
      $scope.search.what = null;
    } else {
      $scope.isEmptyWhere = true;
      $scope.search.where = null;
      $scope.whereClearButtonEnabled = false;
    }
  };

  $scope.submit = function (event, isValid) {
    if (event) {
      event.preventDefault();
    }
    if (isValid) {
      $scope.invalidSearchForm = false;
      NavigationService.toSearch(
        {
          what: $scope.search.what,
          where: $scope.search.where,
          distance: {
            autoexpanded: $scope.distance.autoexpanded,
            enabled: $scope.distance.enabled,
            selected: SearchFormService.search.distance.selected,
            selectedText: SearchFormService.search.distance.selectedText,
            text: SearchFormService.search.distance.text,
            value: $scope.distance.value,
          },
        },
        $scope.search.jobtype,
        ($scope.search.joblocation = [
          $scope.search.joblocation1,
          $scope.search.joblocation2,
          $scope.search.joblocation3,
        ]
          .filter((x) => typeof x === 'string' && x.length > 0)
          .join()),
        ($scope.search.contracttype = [
          $scope.search.contracttype1,
          $scope.search.contracttype2,
          $scope.search.contracttype3,
          $scope.search.contracttype4,
          $scope.search.contracttype5,
          $scope.search.contracttype6,
        ]
          .filter((x) => typeof x === 'string' && x.length > 0)
          .join()),
        $scope.search.sortBy
      );
    } else {
      $scope.invalidSearchForm = true;
    }
  };

  $scope.setDistanceFilter = function (distance) {
    if ($scope.search.where) {
      $scope.distance.value = distance.value;
      $scope.distance.label =
        distance.value === 'auto'
          ? Messages.DistanceFiltersAutoTitle
          : `${Messages.SortFilterTitle}\xa0${distance.text}`;
    }
  };
}

function MySearchesAsideCtrl(MySearchesService, $scope, searchUrlFormatter) {
  $scope.$watch(
    () => MySearchesService.statements,
    (statements) => {
      $scope.statements = statements.map((statement) => ({
        ...statement,
        url: searchUrlFormatter({
          what: statement.what,
          where: statement.where,
        }),
      }));
    },
    true
  );
}
